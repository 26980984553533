import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

function Privacypolicy() {
  return (
    <div className="container">
        <div className="fnt-16">
            <h1 className="my-5 py-5 text-center fw-bold">Privacy Policy</h1>
            <div className="mb-4 mt-5">DripFunnel is a web services platform that automates certain networking tasks on LinkedIn and Gmail. Our service imitates human behavior and makes it easy for its users to network efficiently.</div>
            <div className="mb-4">This privacy notice explains how we collect, share and protect business information.</div>
            <div className="fw-bold mb-4">Registration</div>
            <div className="mb-4">To create an account you need to provide a Google, Microsoft or email account (it will provide us email, name and profile picture) and third party login credentials. If you register for a paid Service, you will need to provide payment (e.g., credit card) and billing information.</div>
            <div className="fw-bold mb-4">Cookies</div>
            <div className="mb-4">We use cookies and similar technologies to recognize you and/or your device(s) on our website. You can control cookies through your browser settings and other tools.</div>
            <div className="fw-bold mb-4">Service Use</div>
            <div className="mb-4">We log usage data when you visit or otherwise use our Services, including our app and platform technology such as when you search or set up campaigns. We use log-ins, cookies, device information and internet protocol (“IP”) addresses to identify you and log your use.</div>
            <div className="fw-bold mb-4">Gmail</div>
            <div className="mb-4">To access the email feature on DripFunnel we will ask to connect with a Gmail account. The App will only use access to:</div>
            <div>
                <ul className="mb-4">
                    <li className="mb-4">Write Gmail messages to send the messages in your name when you set up an email campaign.</li>
                    <li className="mb-4">Read Gmail message bodies (including attachments), metadata, headers, and settings to verify when your email has been responded and notify you on your dashboard.</li>
                </ul>
            </div>
            <div className="mb-4">DripFunnel will not transfer this Gmail data to others unless doing so is necessary to provide and improve these features, comply with applicable law, or as part of a merger, acquisition, or sale of assets. We will not use this Gmail data for serving advertisements. We will not allow humans to read this data unless we have your affirmative agreement for specific messages, doing so is necessary for security purposes such as investigating abuse, to comply with applicable law, or for DripFunnel's internal operations and even then only when the data have been aggregated and anonymized.</div>
            <div className="mb-4">DripFunnel's use and transfer to any other app of information received from <a className="accent-color" href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes">Google APIs will adhere to Google API Services User Data Policy</a>, including the Limited Use requirements.</div>
            <div className="mb-4">
                <span className="fw-bold">Disclosure of Anonymized Information:</span> DripFunnel reserves the right to use and disclose anonymized information, aggregated information or publicly available information that has not been combined with nonpublic Personal Information for any purposes including without limitation DripFunnel’s internal use and research. However, DripFunnel will not disclose information, even in anonymized, aggregate, or derivative forms, that is made available by our connection to email providers such as Gmail, including API calls relating to checking, reading, or sending email, in accordance with the <a className="accent-color" href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes">Additional Requirements for Restricted Scopes</a>  as specified by Google. When connecting to email providers such as Gmail,DripFunnel will only make use of the information necessary to render our service so that we can display our user interface and perform requested actions on your account.
            </div>
            <div className="fw-bold mb-4">Data Retention</div>
            <div className="mb-4">We retain your personal data while your account is in existence or as needed to provide you Services. This includes data you have provided to us and data generated or inferred from your use of our Services. We will retain your information and keep your data until you decide to close your account. Once you close your account, we delete all your data from our systems.</div>
            <div className="fw-bold mb-4">Security</div>
            <div className="mb-4">We implement security safeguards designed to protect your data, such as HTTPS. We regularly monitor our systems for possible vulnerabilities and attacks. Our applications pseudonymize data to ensure the privacy of data subjects. Any attributes that don’t need to remain in its original form is truncated to remove any possibility to be linked back to a specific data subject. However, we cannot warrant the security of any information that you send us. There is no guarantee that data may not be accessed, disclosed, altered, or destroyed by breach of any of our physical, technical, or managerial safeguards.</div>
            <div className="fw-bold mb-4">Lawful Bases for Processing</div>
            <div className="mb-4">We will only collect and process personal data about you where we have lawful bases. Lawful bases include consent (where you have given consent), contract (where processing is necessary for the performance of a contract with you (e.g. to deliver the DripFunnel Services you have requested)) and “legitimate interests”.</div>
            <div className="mb-4">Where we rely on your consent to process personal data, you have the right to withdraw or decline your consent at any time and where we rely on legitimate interests, you have the right to object. If you have any questions about the lawful bases upon which we collect and use your personal data, please contact us at <a className="accent-color" href="https://www.dripfunnel.com/">dripfunnel.com</a>.</div>
            <div className="fw-bold mb-4">Marketing & Data Sharing</div>
            <div className="mb-4">We do not share your personal data with any third parties for their direct marketing purpose.</div>
            <div className="fw-bold mb-4">Contact Information</div>
            <div className="mb-5">If you have questions or complaints regarding this Policy, please first contact us at <a className="accent-color" href="https://www.dripfunnel.com/">dripfunnel.com</a></div>
        </div>
    </div>
  );
}

export default Privacypolicy;
